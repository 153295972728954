import { useEffect, useState } from 'react';

// function getInitialTheme() {
//     var d = new Date();
//     var currentHour = d.getHours();
//     console.log("curr hour:", currentHour)
//     if (currentHour >= 19 || currentHour <= 6) {
//         return 'dark'
//     } else {
//         return 'light'
//     }
// }

export const useTheme = () => {
    const [theme, setTheme] = useState('light');
    const [mountedComponent, setMountedComponent] = useState(false)
    const setMode = mode => {
        window.localStorage.setItem('theme', mode)
        setTheme(mode)
    };

    const themeToggler = () => {
        theme === 'light' ? setMode('dark') : setMode('light')
    };

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme');
        localTheme ? setTheme(localTheme) : setMode('light')
        setMountedComponent(true)
    }, []);

    return [theme, themeToggler, mountedComponent]
};